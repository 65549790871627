/* eslint-disable @typescript-eslint/no-unnecessary-condition */
'use client';

import { RedirectAppWebRouterError } from '@deltasierra/frontend/errors';
import { ReactNode, useEffect } from 'react';
import { useAppWebRouter, useCrossPlatform } from '../../../hooks';

// This is a quick hack to avoid showing this page in production
const SHOULD_REDIRECT = true;

export default function HomePage(): ReactNode {
    const appWebRouter = useAppWebRouter();

    useEffect(() => {
        if (SHOULD_REDIRECT) {
            setTimeout(() => {
                throw new RedirectAppWebRouterError('/login');
            }, 5000);
        }
    }, [appWebRouter]);

    const { isRunningOnMobileApp, platform } = useCrossPlatform();
    return (
        <pre style={{ display: SHOULD_REDIRECT ? 'none' : 'block' }}>
            {'CrossPlatform: '}
            {JSON.stringify(
                {
                    isRunningOnMobileApp,
                    platform,
                },
                null,
                2,
            )}
            <br />
            {'Next.js: app-router'}
            <br />
            {'Styled & themed with Material UI'}
            <br />
            {'Build id: '}
            {BUILD_ID}
            <br />
            {'Build date: '}
            {BUILD_DATE}
        </pre>
    );
}

HomePage.displayName = 'HomePage';
